"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("hammerjs");
const core_1 = require("@angular/core");
const environment_1 = require("./environments/environment");
const hmr_1 = require("hmr");
const Sentry = require("@sentry/angular");
const tracing_1 = require("@sentry/tracing");
const integrations_1 = require("@sentry/integrations");
const __NgCli_bootstrap_1 = require("./app/app.module.ngfactory");
const __NgCli_bootstrap_2 = require("@angular/platform-browser");
const loadPardotScript = () => {
    const node = document.createElement('script');
    node.src = 'assets/js/pardot.js';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
};
const ɵ0 = loadPardotScript;
exports.ɵ0 = ɵ0;
if (environment_1.environment.application.branch === 'bmp-production') {
    Sentry.init({
        dsn: environment_1.environment.services.sentry.sentryDsnVendor,
        autoSessionTracking: true,
        environment: environment_1.environment.application.branch,
        release: environment_1.environment.application.buildNumber,
        integrations: [
            new tracing_1.Integrations.BrowserTracing({
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new integrations_1.CaptureConsole({
                levels: ['error'],
            }),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
    // Need to load pardot only in production
    loadPardotScript();
}
if (environment_1.environment.production) {
    core_1.enableProdMode();
}
const bootstrap = () => __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
const ɵ1 = bootstrap;
exports.ɵ1 = ɵ1;
if (environment_1.environment.hmr) {
    if (module['hot']) {
        hmr_1.hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else {
    bootstrap()
        .then((success) => console.log(`Bootstrap success`))
        .catch((err) => console.error(err));
}
