import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

const loadPardotScript = () => {
    const node = document.createElement('script');
    node.src = 'assets/js/pardot.js';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
};

if (environment.application.branch === 'bmp-production') {
    Sentry.init({
        dsn: environment.services.sentry.sentryDsnVendor,
        autoSessionTracking: true,
        environment: environment.application.branch,
        release: environment.application.buildNumber,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new CaptureConsole({
                levels: ['error'],
            }) as any,
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    // Need to load pardot only in production
    loadPardotScript();
}

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap()
        .then((success) => console.log(`Bootstrap success`))
        .catch((err) => console.error(err));
}
